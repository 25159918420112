import localStorage from "./local-player-storage";
import config from "../config/";

const playerChangeListeners = new Set();

async function fetchNbaApi(endpoint, method) {
  return fetch(config.NbaBaseUrl + endpoint, {
    method,
    headers: {
      Accept: "application/json"
    }
  }).then(response => response.json());
}

export function getAllPlayers() {
  return fetchNbaApi("/players");
}

let cachedPlayers;
export async function searchForPlayer(searchTerm) {
  if (cachedPlayers && cachedPlayers.length > 0) {
    return cachedPlayers.filter(player =>
      player.name.toUpperCase().includes(searchTerm.toUpperCase())
    );
  } else {
    try {
      const players = await getAllPlayers();
      cachedPlayers = players;
      return players.filter(player =>
        player.name.toUpperCase().includes(searchTerm.toUpperCase())
      );
    } catch (exception) {
      console.error(exception);
      const resultPlayers = cachedPlayers && [];
      return Promise.resolve(resultPlayers);
    }
  }
}

export function addPlayer(playerId) {
  localStorage.addPlayerToFavorites(playerId);
  for (let func of playerChangeListeners.values()) {
    func();
  }
}

export function removePlayer(playerId) {
  localStorage.removePlayerFromFavorites(playerId);
  for (let func of playerChangeListeners.values()) {
    func();
  }
}

export async function getFavoritePlayers() {
  const favoritePlayerIds = localStorage.getFavoritePlayers();
  return getStatsforPlayers(favoritePlayerIds);
}

export async function getStatsForPlayer(playerId) {
  return fetchNbaApi("/players/" + playerId);
}

export async function getStatsforPlayers(playerIds) {
  return Promise.all(
    playerIds.map(playerId => getStatsForPlayer(playerId))
  ).catch(console.error);
}

export function addPlayersChangeListener(func) {
  playerChangeListeners.add(func);
  return () => playerChangeListeners.delete(func);
}

export default {
  getAllPlayers,
  searchForPlayer,
  addPlayer,
  removePlayer,
  getStatsForPlayer,
  getStatsforPlayers,
  getFavoritePlayers,
  addPlayersChangeListener
};
